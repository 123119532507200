@import "../imports/tailwind-colors.scss";

$primary-25: rgb(248, 253, 255);
$primary-50: #ecf9ff;
$primary-100: #d5efff;
$primary-200: #b3e5ff;
$primary-300: #7fd6ff;
$primary-400: #43bdff;
$primary-500: #189bff;
$primary-600: #0079ff;
$primary-700: #0061fb;
$primary-800: #034cc5;
$primary-900: #0a459e;
$primary-950: #0c2b5f;

$rgb-primary-50: rgba(236, 249, 255, 1);
$rgb-primary-100: rgba(213, 239, 255, 1);
$rgb-primary-200: rgba(179, 229, 255, 1);
$rgb-primary-300: rgba(127, 214, 255, 1);
$rgb-primary-400: rgba(67, 189, 255, 1);
$rgb-primary-500: rgba(24, 155, 255, 1);
$rgb-primary-600: rgba(0, 121, 255, 1);
$rgb-primary-700: rgba(0, 97, 251, 1);
$rgb-primary-800: rgba(3, 76, 197, 1);
$rgb-primary-900: rgba(10, 69, 158, 1);
$rgb-primary-950: rgba(12, 43, 95, 1);

/*COLORS FROM FLAT UI COLOR PALETTE 1*/
$teal-light: #1abc9c;
$teal-dark: #16a085;
$green-light: #2ecc71;
$green-dark: #27ae60;
$blue-light: #3498db;
$blue-dark: #2980b9;
$violet-light: #9b59b6;
$violet-dark: #8e44ad;
$dark-light: #34495e;
$dark-dark: #2c3e50;
$yellow-light: #f1c40f;
$yellow-dark: #f39c12;
$orange-light: #e67e22;
$orange-dark: #d35400;
$red-light: #e74c3c;
$red-dark: #c0392b;
$white-light: #e0e0e5;
$white-dark: #bdc3c7;
$grey-light: #95a5a6;
$grey-dark: #7f8c8d;

$danger: $red-light;
$warning: $yellow-light;
$success: $green-light;
$info: $blue-light;
$link: $violet-light;

$full-white: #fff;
$full-black: #000;

/*3 COLORS THEME*/
$light-bg: #fcfcfc;
$light-panel: #e8e8e8;
$light-details: #d8d8d8;
$light-text: $text;

$dark-bg: #101016;
$dark-details: #161621;
$dark-panel: #202026;
$dark-text: $text-light;

$bg: $primary-25;
$bg-panel: $full-white;
$text: $gray-800;

$primary: $primary-600;
$secondary: $sky-600;
