@charset "utf-8";
@import '../../node_modules/bulma/bulma.sass';

// Shared styles
@import './shared/colors.scss'; // important to be first import
@import './shared/animations.scss';
@import './shared/button.scss';

// Components styles
@import './components/Navbar.scss';
@import './components/Establishments.scss';
@import './components/Payments.scss';

// Common Components styles
@import './components/Paginator.scss';
@import './components/AnimatedLoader.scss';
@import './components/Dropdown.scss';
@import './components/Modal.scss';

html {
  background-color: $bg;
}


body {
  background-color: $bg;
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.grid-bg-light {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(15 23 42 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

.grid-bg-dark {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(215 223 242 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

.basic-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1500px;
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 2rem;

  .sbz-title-container {
    width: 100%;
    height: 4.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title-icon {
      font-size: 50px;
      margin-right: 0.65rem;
      font-size: 40px;

      background: -moz-linear-gradient(
        135deg,
        $primary-500 0%,
        $primary-800 100%
      );
      background: -webkit-linear-gradient(
        135deg,
        $primary-500 0%,
        $primary-800 100%
      );
      background: linear-gradient(135deg, $primary-500 0%, $primary-800 100%);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .sbz-title {
      font-family: 'Montserrat', sans-serif;
      font-weight: 900;
      font-size: 1.4rem;
    }

    label {
      font-style: italic;
      font-size: 1.05rem;
      margin-top: -0.55rem;
      opacity: 0.5;
      font-weight: 600;
    }
  }

  .sbz-page-container {
    animation: 0.7s fadeInTop ease;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.title-font {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  background-image: linear-gradient(315deg, $dark-dark 0%, $dark-light 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: transparent;
}

.basic-container {
  min-height: 100vh;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.flex-center {
  align-items: center;
  place-content: center;
}

.full-width {
  width: 100%;
}

.help {
  animation: fadeInTop 0.3s ease;
}

.success-text {
  color: $green-dark;
}
.warn-text {
  color: $yellow-dark;
}
.danger-text {
  color: $red-dark;
}
.font-bold {
  font-weight: 700;
}
.white-box {
  border-radius: 0.5rem;
  background: #fff;
  border: 1px solid #00000022;

  &:not(.no-padding) {
    padding: 0.5rem 0.75rem;
  }

  .title-box {
    padding: 0.5rem 0.75rem;
    font-size: 1.1rem;
    border-bottom: 1px solid #00000022;

    h3 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 900;
    }
  }
  .container-box {
    padding: 0.5rem 0.75rem;
  }
}

.dropdown-button {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.auth-container {
  max-width: 500px;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10rem;
}

.login-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: calc(100% - 1rem);
  margin: 0 0.5rem 1rem;

  .image-logo-container {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-text {
    margin-left: 0.5rem;
    font-size: 2.5rem;
  }

  .logo-image {
    height: 4.25rem;
  }
}

.login-box {
  width: calc(100% - 1rem);
  margin: 0 0.5rem;
  padding-bottom: 0.75rem;
}

.login-button {
  width: 100%;
  margin-top: 0.5rem;
}

.text-ellipsis {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
