.main-container-navbar {
  padding-left: 16rem;
}

.navbar {
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  position: fixed;
  background-color: $bg-panel;

  border-right: 1px solid #00000022;
  width: 16rem;

  hr {
    margin: 0.75rem 1rem;

    &:first-child{
      margin-top: 0.25rem;
    }

    height: 1px;
    background-color: #00000022;
  }

  .navbar-logo {
    font-size: 1.75rem;
    color: $text;
    display: flex;
    flex-direction: row;
    align-items: center;

    // Micro hr adjusting
    height: calc(4.25rem - 3px);

    .nav-link-logo {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .image-logo-container {
        width: 5rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        // Micro logo adjusting
        margin-left: 2px;
      }

      .link-text {
        margin-left: -0.5rem;
      }

      .logo-image {
        height: 2.75rem;
      }
    }
  }

  .navbar-home {
    flex: 1;
  }
  
  .nav-dropdown-container {
    width: calc(100% - 2rem);
    margin: 0 1rem 0.5rem;
  }

  .nav-item {
    list-style: none;
    cursor: pointer;

    .nav-link {
      text-decoration: none;
      display: flex;
      align-items: center;

      transition: filter 300ms ease;
      transition: background-color 300ms ease;

      margin: 0 1rem 0.25rem;
      border-radius: 0.5rem;

      .link-text {
        font-size: 1em;
        white-space: nowrap;
        margin-left: -0.5rem;
        color: $text;
      }

      .msymbol {
        text-align: left;
        margin: 0.5rem 0;
        width: 4.25rem;
        padding-left: 0.75rem;
        font-size: 1.8em;
        transition: opacity 0.3s;
        opacity: 0.25;

        background: -moz-linear-gradient(
          -135deg,
          $primary-800 0%,
          $primary-400 100%
        );
        background: -webkit-linear-gradient(
          -135deg,
          $primary-800 0%,
          $primary-400 100%
        );
        background: linear-gradient(
          -135deg,
          $primary-800 0%,
          $primary-400 100%
        );
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:not(.disabled) {
        &:not(.active) {
          &:hover {
            background-color: $primary-50;

            .msymbol {
              opacity: 0.5;
            }
          }
        }
      }

      &.active {
        background-color: $primary-50;

        .msymbol {
          opacity: 1;
        }
      }
    }
  }

  .navbar-logout {
    .nav-item {
      margin-bottom: 0.75rem;
      .nav-link {
        background-color: #c0392b22;

        &:not(:active) {
          &:hover {
            background-color: #c0392b44;
          }
        }

        &:active {
          background-color: #c0392b66;
        }

        .msymbol {
          opacity: 1;
          background: $red-dark;

          -webkit-background-clip: text;
          -moz-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// remove button style
.nav-link,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
