.button {
  cursor: pointer;
  border-radius: 0.4rem;

  &.is-primary {
    font-weight: bold;
    background: $primary-600;
    border: none;

    &:hover {
      background: $primary-500;
      opacity: 0.85;
      border: none;
    }
    &:active {
      background: $primary-400;
      opacity: 0.75;
      border: none;
    }

    &:focus {
      background: $primary-500;
      opacity: 0.75;
      border: none;
    }

    &:disabled {
      background: $primary-600;
      border: none;
      opacity: 0.5;
    }
  }

  &.secondary {
    background-color: $secondary;
  }
  &.danger {
    background-color: $danger;
  }
  &.warning {
    background-color: $warning;
  }
  &.info {
    background-color: $info;
  }
  &.link {
    background-color: $link;
  }
  &.success {
    background-color: $success;
  }
  &.transparent {
    background-color: transparent;
  }

  &.is-primary-gradient {
    font-weight: bold;
    background: -moz-linear-gradient(
      135deg,
      $primary-500 0%,
      $primary-800 100%
    );
    background: -webkit-linear-gradient(
      135deg,
      $primary-500 0% $primary-800 100%
    );
    background: linear-gradient(135deg, $primary-500 0%, $primary-800 100%);
    color: #fff;
    border: none;

    &:hover {
      opacity: 0.85;
      border: none;
    }
    &:active {
      opacity: 0.75;
      border: none;
    }

    &:focus {
      opacity: 0.75;
      border: none;
    }

    &:disabled {
      border: none;
      opacity: 0.5;
    }
  }
}
