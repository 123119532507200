.table-list {
  border: 1px solid #00000022;
  border-radius: 0.5rem;
  overflow: hidden;
}

.table-item {
  padding: 0.4rem 1rem;

  &.odd {
    background: #ffffffbb;
  }

  &.even {
    background: #ffffff66;
  }

  &.header-item {
    background: #ffffff;
    font-family: 'Montserrat', sans-serif;
      font-weight: 900;
      font-size: 0.95rem;
  }
}
