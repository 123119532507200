.estab-item {
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  &:not(:last-child){
    margin-bottom: 0.75rem;
  }

  .estab-icon {
    font-size: 3rem;
    margin-right: 0.75rem;
    opacity: 0.25;
  }

  &.active {
    .estab-icon {
      opacity: 1;
      color: $primary-600;
    }
  }

  h2 {
    font-weight: 900;
  }
}
